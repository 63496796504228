/* Inter: Regular 400 */
@font-face {
  font-family: 'Inter';
  src: url('./inter/Inter.ttf');
  font-weight: 400;
  font-style: normal;
}

/* ATB TT Norms: Regular 400 */
@font-face {
  font-family: 'ATB TT Norms';
  src: url('./atbTTNorms/ATB TT Norms Regular.ttf');
  font-weight: 400;
  font-style: normal;
}