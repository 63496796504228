@import './modules/index';
@import './styles/index';

:root {
  // Content Sizing
  --unauthed-content-max-width: 424px;
  --max-width-sm: 400px;
  --max-width-md: 600px;
  --max-width-lg: 800px;
  --stretched-content-max-width: 1200px;
  --desktop-banner-photo-height: 352px;
  --mobile-banner-photo-height: 180px;
  --min-content-width: 256px;
  --desktop-header-height: 64px;
  --mobile-header-height: 56px;
  --narrow-sidebar-width: 350px;

  // Material UI References
  --small-icon-size: 20px;
  --default-icon-size: 24px;
  --large-icon-size: 35px;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-size: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  font-size: 16px;
  height: 100%;
}
body {
  font-family: 'Inter';
  height: inherit;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  padding-inline-start: var(--spacing-s3);
  margin: 0;
}

input, textarea, select, button {
  font-family: 'Inter'
}

#root {
  height: inherit;
}

.orig-link {
  color: inherit;
  cursor: pointer;
  text-decoration-color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.orig-step-link {
  cursor: pointer;
}
